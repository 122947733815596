<template>
  <div class="market-client">
    <!-- top -->
    <div class="top-nav">
      <img v-cdnimg src="/Account/images/third/cloud/logo_qkpaas.png" alt="">
    </div>
    <!-- intro -->
    <div class="intro">
      <div class="secheader">
        <div class="row-1">
          <h1>智慧课堂</h1>
          <img v-cdnimg src="/Account/images/third/cloud/secrole2.png" alt="">
        </div>
        <div class="row-2">将云计算平台与移动互联技术应用于基础教育，用信息化科技关联教师、学生、家长，用大数据服务贯穿课前、课中、课后。</div>
        <div class="row-2">以灵活易用的备授课系统、丰富的教学资源和教学工具，为师生提供优质的数字化课堂教学服务，让教学变得生动有趣，课堂组织高效有序、教学效果反馈及时、教育评价有据可依。</div>
      </div>
    </div>
    <!-- content -->
    <div class="content">
      <div class="left-btn" @click="onDownload">
        <span>下载客户端</span>
      </div>
      <div class="right-btn" @click="onStartUp">
        <span>启动客户端</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketClient',
  props: [],
  components: {},
  data() {
    return {
      url: 'https://appdata.forclass.net/download/CBClass8.61.215_Setup.exe',
    };
  },
  watch: {},
  computed: {},
  methods: {
    onDownload() {
      this.onTagClick(this.url);
    },
    onStartUp() {
      let session = this.$route.query.session;
      let url = `forclassprotocol://session=${session}`;
      this.onTagClick(url);
    },
    onTagClick(url) {
      let a = document.createElement('a');
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.market-client {
  cursor: default;
  width: 100vw;
  height: 100vh;
  .top-nav {
    height: 80px;
    padding-left: 150px;
    background-color: #6e818e;
    opacity: 0.8;
    display: flex;
    align-items: center;
    img {
      height: 56px;
    }
  }
  .intro {
    height: 470px;
    background: url('~@Account/images/third/cloud/banner-download.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .secheader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 70%;
      color: #FFF;
      .row-1 {
        margin-bottom: 10px;
        h1 {
          display: inline-block;
          font-weight: normal;
          margin-right: 20px;
        }
      }
      .row-2 {
        line-height: 35px;
        font-size: 18px;
      }
    }
  }
  .content {
    height: calc(100vh - 550px);
    padding: 0 8%;
    background-color: #e3f0f4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #131314;
    .left-btn {
      width: 334px;
      height: 312px;
      position: relative;
      cursor: pointer;
      background: url('~@Account/images/third/cloud/sec_downbg.png') no-repeat center -312px;
      &:hover {
        background: url('~@Account/images/third/cloud/sec_downbg.png') no-repeat center 0;
      }
    }
    .right-btn {
      width: 334px;
      height: 312px;
      position: relative;
      cursor: pointer;
      background: url('~@Account/images/third/cloud/sec_shoukebg.png') no-repeat center -312px;
      &:hover {
        background: url('~@Account/images/third/cloud/sec_shoukebg.png') no-repeat center 0;
      }
    }
    span {
      position: absolute;
      bottom: 80px;
      left: 124px;
    }
  }
}
</style>
